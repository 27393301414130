;(function(){
	var Product = $('.product-details');

	Product.find('.owl-carousel')
    .each(function (i, el) {
      var $el = $(el);
      $el.owlCarousel({
        loop: $el.find('.item').length > 1,
        items: 1,
        nav: true,
        navText: ['<i class="ion-android-arrow-dropleft"></i>', '<i class="ion-android-arrow-dropright"></i>']
      })
    });

	Product.find('.play').swipebox();
})();