;(function(){
    var Header = $('.main-header');
    var height = Header[0].getBoundingClientRect().height;

    Header.on('click', '.langs', function (e) {
        $(this).toggleClass('open');
        if($(e.target).closest('.selected').length){
            Header.removeClass('open');
            e.preventDefault();
        }
    });
    Header.on('touchend', function (e) {
        if($(e.target).is('.handler') || $(e.target).is('.main-header')){
            e.preventDefault();

            Header.toggleClass('open');
            return false;
        }
    });
    function checkFixed() {
        var top = $(window).scrollTop();
        var condition = top >= height;
        Header.toggleClass('fixed', condition);
    }

    $(window).on('scroll', checkFixed);
    $(window).resize(function () {
        height = Header[0].getBoundingClientRect().height;
    });
    checkFixed();

    $.ajax({
        url: 'translations/index.php',
        type: 'get',
        dataType: 'json',
        data: {
            lang: $('html').attr('lang')
        }
    }).done(function (res) {
        window.i18n = new Translate(res);
    });

    $('body').on('click', '[data-scroll]', function (e) {
        e.preventDefault();
        var target = $($(this).data('scroll'));
        var top = target.offset().top;
        if(top > 0){
            Header.addClass('fixed');
        }
        setTimeout(function () {
            top -= Header.height() - 10;
            $('body, html').animate({scrollTop: top+'px'});
        });
    });
})();