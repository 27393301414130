;(function(){
	function Translate(dict) {
    var translations = dict;

    return {
      _: function (key, params) {
        params = params || {};
        var keys = key.split('.');
        var trans = translations;
        while(keys.length){
          var _key = keys.shift();
          if(!trans.hasOwnProperty(_key)) return key;
          trans = trans[_key];
        }
        if(typeof trans == 'string'){
          for(var i in params){ if(!params.hasOwnProperty(i)) continue;
            var param = params[i];
            var rgx = new RegExp('\{\{'+i+'}}', 'g');
            trans = trans.replace(rgx, param);
          }
        }
        return trans;
      }
    }
  }

  window.Translate = Translate;
})();