;(function(){
  var form = $('.form-validate');
  var SPMaskBehavior = function (val) {
      return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    spOptions = {
      onKeyPress: function(val, e, field, options) {
        field.mask(SPMaskBehavior.apply({}, arguments), options);
      }
    };

  function validate(i, field) {
    field = $(field);
    field.removeClass('is-err');
    if(!field.is('[required]')) return false;
    if(field.val().length) return false;
    field.addClass('is-err');
    return true;
  }

  form.on('keyup input change', 'input, select, textarea', function () {
    if(form.submitted){
      validate(0, $(this));
    }
  });

  form.on('submit', function (e) {
    form.submitted = true;
    var fields = form.find('input, select, textarea');
    var empties = fields
      .filter(validate);
    if(empties.length){
      e.preventDefault();
      form.find('#form-message').html(i18n._('default.form-all'));
      return false;
    }
  });

  form.find('.phone').mask(SPMaskBehavior, spOptions);
})();