;(function(){
	var Home = $('.home');

	var Covers = Home.find('.cover');
  if(Covers.length){
    Covers.active = Covers.find('.item').first().addClass('active');
    if(Covers.active.siblings().length > 0){
      setInterval(function () {
        Covers.active = Covers.find('.item.active');
        Covers.active.removeClass('active');
        if(!Covers.active.next().addClass('active').length){
          Covers.active.siblings('.item').first().addClass('active')
        }
      },5000);
    }
  }

})();