;(function(){
	var form = $('.form-validate');

	var lock = setInterval(function () {
	    if(window.i18n === undefined) return true;
	    clearInterval(lock);
        form.find('.type').on('change', function () {
            var val = $(this).val();
            switch (val){
                case i18n._('contact.be-a-distributor.form.type.values.0'): // pessoa física
                    form.find('.cpf').addClass('show').attr('required', true);
                    form.find('.cnpj').removeClass('show').attr('required', false);
                    break;
                case i18n._('contact.be-a-distributor.form.type.values.1'): // pessoa jurídica
                    form.find('.cnpj').addClass('show').attr('required', true);
                    form.find('.cpf').removeClass('show').attr('required', false);
                    break;
                default:
                    form.find('.cpf, .cnpj').removeClass('show').attr('required', true);
            }
        }).trigger('change');

        form.find('.cpf').mask('000.000.000-00');
        form.find('.cnpj').mask('00.000.000/0000-00');
    },100);
})();